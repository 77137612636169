<template>
  <div>
    <div class="" v-if="isLoadFirstTime">
      <div class="row">
        <div class="col-12 w-100">
          <div class=" text-left card analyse-box mt-4">
            <div class="card-body ">
              <div
                class="bg-preload my-2"
                style="height: 40px; width: 300px; border-radius: 4px"
              >
                <div class="into-preload"></div>
              </div>
              <div
                class="bg-preload my-2"
                style="height: 40px; width: 100%; border-radius: 4px"
              >
                <div class="into-preload"></div>
              </div>
              <b-skeleton-table
                :rows="5"
                :columns="4"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="d-flex align-items-center justify-content-between">
      <div class="v16-description" style="color: #73738D;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 28px;">Products Statistics</div>
<div class="d-flex gap align-items-center">
  <button @click="$refs.exportAnalysisProducts.openExportModal()" class="export-button">  <i
                      class="fa-solid mr-2 fa-file-export "
                    ></i>
                    Export </button>
  <button @click="$refs.resetProductAnalytics.openResetModal()" class="export-button"> <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
  <path d="M2.63263 11.243C3.11896 12.6233 4.04073 13.8081 5.25906 14.619C6.47739 15.4299 7.92627 15.8229 9.3874 15.7388C10.8485 15.6548 12.2427 15.0981 13.36 14.1528C14.4772 13.2075 15.257 11.9247 15.5817 10.4978C15.9065 9.07081 15.7586 7.57697 15.1605 6.24132C14.5623 4.90568 13.5463 3.8006 12.2654 3.09258C10.9846 2.38455 9.5083 2.11195 8.05903 2.31584C5.61219 2.66007 3.9956 4.43357 2.25 6M2.25 6V1.5M2.25 6H6.75" stroke="#73738D" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
</svg> Reset Data</button>
</div>
      </div>
      <div class="row">
        <div class="col-12 w-100">
          <div class="card  text-left analytics-box mt-4">
            <div class="card-body ">
             
              <FilterBox
                v-model.trim="filterTitle"
                :filterBtn="false"
                placeholder="Search Product..."
              ></FilterBox>
              <div class="row my-3 AnsStatsTable">
                <!-- table -->
                <b-table
                 head-class="text-center"
                  class="leadTable"
                  :stickyColumn="true"
                  :items="items"
                  sort-icon-left
                  :sort-by.sync="tableSorting.order_by"
                  :sort-desc.sync="tableSorting.sort"
                  @sort-changed="fetchData"
                  :busy.sync="isAnalysisLoading"
                  :fields="fields"
                  v-if="items.length"
                  responsive
                ></b-table>
                <!-- v-else -->
             <div class="" v-if="items.length">
               <QuizellPagination  :total-rows="totalRows" :currentPage="currentPage"
                 :per-page="perPage"    @update="updatePagination" @UpdatePerPage="updatePerPage"></QuizellPagination>
             </div>
             <template v-else>

<div class="d-flex col-12 justify-content-center align-items-center"  >

<EmptyTable >
<template #title>
No product found
</template>
<template #description>
Time to fill your shelves or refine your search. Remember, your results will appear right here.
</template>
</EmptyTable>
</div>

</template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- answer statics end-->
      <a ref="downloadLink" style="display: none"></a>
      <ExportAnalytics
        :fields="['all', 'filtered']"
        :isFileDownload="isLoadingExport"
        id="exportAnalyticsProducts"
        @export="exportProduct($event)"
        ref="exportAnalysisProducts"
      ></ExportAnalytics>
      <ResetAnalytics
        :quizzes="quizzes"
        id="resetProductAnalytics"
        :isResetting="isLoadingReset"
        ref="resetProductAnalytics"
        @reset="resetProduct"
      ></ResetAnalytics>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash";
import axios from "axios";
import FilterBox from "../Layout/FilterBox.vue";
import { mapGetters } from "vuex";
import ResetAnalytics from "./ResetAnalytics.vue";
import ExportAnalytics from "./ExportAnalytics.vue";
import QuizellPagination from "../Layout/QuizellPagination.vue";
import EmptyTable from "../Layout/EmptyTable.vue";
// import CostStats from "./CostStats.vue";
// import LoaderComp from "../../customize/component/LoaderComp.vue";
export default {
  props: [
    "Token",
    "QuizKey",
    "quizzes",
    "startDate",
    "endDate",
    "quizIds",
    "selectedDateValue",
  ],
  components: {
    FilterBox,
    EmptyTable,
    ExportAnalytics,
    ResetAnalytics,
    QuizellPagination,
    // CostStats,
    // LoaderComp,
  },
  data() {
    return {
      isLoadFirstTime: true,
      isLoadingExport: false,
      filterTitle: "",
      items: [],
      currentPage: 1,
      totalRows: 10,
      perPage: 10,
      fields: [
        {
          key: "title",
          label: "Title",
          sortable: true,
          thStyle: { maxWidth: "80%", "vertical-align": "middle" },
         
           tdClass: " w-30 align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "add_to_cart_count",
          label: "Total Buy now clicks",
          sortable: true,
           tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "avg_ranking",
          label: "Average Ranking",
          sortable: true,
           tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading",
        },
        {
          key: "recommendation_count",
          label: "Recommendation Count ",
          sortable: true,
           tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading",
        },
      ],
      QuizID: "",
      tableSorting: {
        order_by: "recommendation_count",
        sort: true,
      },
      sorting: {
        order_by: "recommendation_count",
        sort: true,
      },
      DateDropdown: [
        {
          text: "All",
          value: "all",
        },
        {
          text: "Last week",
          value: "lastWeek",
        },
        {
          text: "Last month",
          value: "lastMonth",
        },
        {
          text: "Custom Date",
          value: "customDate",
        },
      ],

      isLoadingReset: false,
      AnswerStats: [],
      mainAnalysis: {},
      graphAnalysis: [],
      isAnalysisLoading: false,
      isV1:false,
    };
  },
  methods: {
    updatePerPage(e){
      this.currentPage=1;
      this.perPage=e;
      this.getAnalyseData(this.isV1);
    },
    updatePagination(e){
      this.currentPage=e;
      this.getAnalyseData(this.isV1);
    },
    async resetProduct(quizIds) {
      this.isLoadingReset = true;

      // const response =
      
      const response = await this.$store.dispatch("fetchData", {
        axiosMethod: "post",
        resource: "analysis",
        method:  this.isV1?'resetProductsOld':`resetProducts`,
        params: { quizIds: quizIds },
      });
      if (response && response.status) {
        this.$toasted.show("Product analysis has been reset.", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        this.getAnalyseData(this.isV1);
      } else {
        this.$toasted.show("Something went wrong.", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
      }
      this.isLoadingReset = false;
      this.$refs.resetProductAnalytics.closeResetModal();
    },
    async exportProduct(category) {
      let data = {};
      if (category == "filtered") {
        let start_date = this.startDate;
        let end_date = this.endDate;
      
        data = {
          search: this.filterTitle,

          start_date: start_date,
          end_date: end_date,
          quizIds: this.quizIds,
        };
      }
      this.isLoadingExport = true;

      const response = await this.$store.dispatch("fetchData", {
        axiosMethod: "post",
        resource: "analysis",
        method: this.isV1?'exportProductsOld':`exportProducts`,
        params: data,
      });
      const blob = new Blob([response], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const downloadLink = this.$refs.downloadLink;
      downloadLink.href = url;
      downloadLink.download = "ProductsAnalytics.csv";
      downloadLink.click();
      window.URL.revokeObjectURL(url);
      this.isLoadingExport = false;
      this.$refs.exportAnalysisProducts.closeExportModal();
    },
    handleProductSearchDebounced: debounce(function () {
       this.getAnalyseData(this.isV1)
    }, 500),
    fetchData(s) {
      this.currentPage = 1;
      this.sorting.order_by=s.sortBy
      this.sorting.sort= s.sortDesc
      this.getAnalyseData(this.isV1)
    },
    ResetCustomDate() {
      this.startDate = null;
      this.endDate = null;
      this.selectedDateValue = { text: "All", value: "all" };
    },
    async getAnalyseData(isV1=false) {    
      this.isAnalysisLoading = true;
      try {
       
        let order_by = this.sorting.order_by;
        let sort = this.sorting.sort ? "desc" : "asc";
        let data = {
          search: this.filterTitle,
          page: this.currentPage,
          per_page:this.perPage,
          sort: sort,
          quizIds: this.quizIds,
          order_by: order_by,
        };
          if (this.startDate && this.endDate) {
  data.start_date = this.startDate;
  data.end_date = this.endDate;
}
this.isV1=isV1;
const api = isV1?'/product/analytics':`/api/v1/product-analytics`
        const response = await axios.post(api, data);
        if(response.status)
        {

          this.items = response.data.data;
          this.perPage = response.data.per_page;
          this.totalRows = response.data.total;
        }
        // if (response.status == 200) {
        // }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.isAnalysisLoading = false;
        this.isLoadFirstTime = false;
      }
    },
  },
  computed: {
    ...mapGetters(["getQuizID"]),
    calculateCompletion() {
      let value;
      if (this.mainAnalysis.start && this.mainAnalysis.submission) {
        value = Math.ceil(
          (this.mainAnalysis.submission / this.mainAnalysis.start) * 100
        );
      }

      return value > 100 ? 100 : value;
    },
    getQuizID() {
      return localStorage.getItem("QuizID");
    },
  },
  watch: {
    filterTitle() {
      this.currentPage = 1;
      this.handleProductSearchDebounced();
    },
  },
  mounted() {
    
  },
  created() {
    this.QuizID = this.$store.state.QuizId;
    // this.getAnalyseData();
  },
};
</script>

<style scoped>
 .analytics-box{
  border-radius: 20px;
background: #FFF;

box-shadow: 0px 4px 10px 0px rgba(10, 4, 17, 0.15);
border:none;
 }
.w-30 {
  width: 30%;
}
.customPagination >>> li > button {
  color: #4d1b7e;
}

.customPagination >>> li.active > button {
  color: white;
  background-color: #4d1b7e !important;
  border-color: #4d1b7e;
}
.customPagination >>> li > button:hover {
  color: white;
  background-color: #6b4690 !important;
  border-color: #4d1b7e;
}
.leadTable >>> thead {
  border-radius: 6px;
  color: black;
  background: rgba(179, 175, 182, 0.07);
  border: none;
}
.leadTable >>> thead tr {
  border-radius: 10px;
}
.leadTable >>> thead th {
  border: none;
}
</style>
