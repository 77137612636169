<template>
    <div class="">
      <!-- v-if="getNavbarData.userType == 'shopify'" -->
      <ModalVue
        :ref="id"
        :id="id"
        title="<span class='mx-2'> Resetting Analytics</span>"
        size="md">
        <div class="mx-2">
           <b-form-radio
              name="export-category"
              v-model="category"
              value="all"
              class="radio-button my-2 text-capitalize">
              all
            </b-form-radio>
            <b-form-radio
              name="export-category"
              v-model="category"
              value="selected"
              class="radio-button my-2 text-capitalize">
              Select Quiz
            </b-form-radio>
            <div class="d-flex flex-wrap px-4" style="gap:7px" >
              <b-form-checkbox
            v-for="quiz in quizzes"
              :key="quiz.id"
               name="export-categorys"
               v-model="quizIds"
               :disabled="category=='all'"
               :value="quiz.id"
               class="radio-button text-capitalize">
               {{ quiz.title }}
             </b-form-checkbox>
            </div>
          <div class="d-flex py-3">

            <div class="">
              <i class="fa-solid fa-triangle-exclamation pr-2 text-warning"></i>
             
            </div>
            <div class="">
               Resetting analytics will irreversibly delete all data.
              
            </div>
          </div>
         
          <div class="d-flex justify-content-between align-items-center">
             <div class="d-flex align-items-center">
                Are you sure to proceed?
               </div>
               <div class="">

                 <Button :is-loading="isResetting" @click="submit()">Reset</Button>
               </div>
          </div>
        </div>
      </ModalVue>
    </div>
  </template>
  <script>
  import ModalVue from "../Layout/Modal.vue";
  // import ModalVue from "../../Layout/Modal.vue";
  import Button from "../Layout/Button.vue";
  export default {
    props:{
      quizzes:Array,
      isResetting:Boolean,
     
      id:{
        default:'Export',
        type:String
      }
    
    },
    watch:{
      category(newValue){
       this.quizIds = newValue=='all'?[]:this.quizIds
      }
    },
    components: {
      ModalVue,
      Button,
    },
  
    data() {
      return {
        quizIds:[],
        fields:['all','selected'],
        category: "all",
      };
    },
    computed: {
    },
    methods: {
      submit(){
        if(this.category!='all'&&(this.quizIds.length<=0))
        {
          this.toast('Please Select Quiz First');
          return
        }
        this.$emit('reset',this.quizIds)
      },
      toast(text) {
        this.$toasted.show(text, {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
      },
      openResetModal() {
         this.category = "all"
        this.$refs[this.id].open();
      },
      closeResetModal() {
        this.$refs[this.id].close();
      },
    },
  };
  </script>
  <style scoped>
  .radio-button
    >>> .custom-control-input:checked
    ~ .custom-control-label::before {
    background: #ffa201;
    border-color: #ffa201;
  }
  .radio-button >>> .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem #ffa201;
  }
  </style>